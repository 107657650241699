import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Services from 'src/components/ClubComponents/Servicos'

function Page() {
  return (
    <>
      <GatsbySeo title="Clube | Serviços | Decathlon" noindex nofollow />
      <Services />
    </>
  )
}

export default Page
